import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

const OrderDetailCard = ({ orderData }) => {
  return (
    <div className="white-card px-3 py-3 my-3">
      <h5 className="card-title">Order Details</h5>
      <div className="horizontal-line" />
      <Row xs="auto" className="pb-3">
        <Col xs={2} className="app-text section-title">
          Order Id
        </Col>
        <Col className="app-text">{orderData.id}</Col>
      </Row>
      <Row xs="auto">
        <Col xs={2} className="app-text section-title">
          Date
        </Col>
        <Col className="app-text">
          {moment(orderData.createdDate).format("DD-MM-YYYY")}
        </Col>
      </Row>
    </div>
  );
};

export default OrderDetailCard;
