import React from "react";
import { Table } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Loader from "../../../components/loader";
import { imagePlaceHolders, s3BucketConstants } from "../../../constants";
import useShopMenu from "./util";

const ShopMenu = () => {
  const { menu, loading } = useShopMenu();

  // console.log("Menu", menu);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page-container">
      <h2 className="page-title">Menu</h2>
      <div className="table-container">
        <Table responsive="sm">
          <thead>
            <tr>
              <th>No.</th>
              <th></th>
              <th>Item Name</th>
              <th>Types</th>
              <th>Price $</th>
            </tr>
          </thead>
          <tbody>
            {menu.map((item, index) => (
              <tr className="table-row" key={item.id}>
                <td>{index + 1}</td>
                <td>
                  {/* <img
                    className="menu-image"
                    src={
                      item.image
                        ? `${s3BucketConstants.IMAGE_BASE_URL}/${item.image}`
                        : imagePlaceHolders.MENU_IMAGE_PLACEHOLDER_75
                    }
                    alt="menu-img"
                  /> */}
                  <LazyLoadImage
                    className="menu-image"
                    width="100%"
                    height="100%"
                    alt={"menu-img"}
                    effect="opacity"
                    placeholderSrc={
                      item.image
                        ? `${s3BucketConstants.IMAGE_BASE_URL}/${item.image}?tr=w-20,h-20,bl-10`
                        : imagePlaceHolders.MENU_IMAGE_PLACEHOLDER_75
                    }
                    src={
                      item.image
                        ? `${s3BucketConstants.IMAGE_BASE_URL}/${item.image}?tr=w-300,h-300`
                        : imagePlaceHolders.MENU_IMAGE_PLACEHOLDER_75
                    }
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.description}</td>
                <td>{item.price}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ShopMenu;
