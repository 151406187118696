import React from "react";

import useOrderDetails from "./util";
import OrderedItems from "./orderItems";
import OrderDetailCard from "./orderDetailCard";
import CustomerDetailCard from "./customerDetailCard";
import PriceDetailCard from "./priceDetails";
import Loader from "../../../../components/loader";

const OrderDetalis = () => {
  const { orderData, orderItems, loading } = useOrderDetails();

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page-container">
      <h2 className="page-title">Order Details</h2>
      <OrderDetailCard orderData={orderData} />
      <OrderedItems data={orderItems} />
      <CustomerDetailCard orderData={orderData} />
      <PriceDetailCard orderData={orderData} />
    </div>
  );
};

export default OrderDetalis;
