const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_HEADER = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Basic ${Buffer.from(
    `apiuser:${process.env.REACT_APP_API_PASSWORD}`
  ).toString("base64")}`,
};

export { BASE_URL, API_HEADER };
