import { Button, TextField } from "@mui/material";
import React from "react";
import { Col, Row } from "react-bootstrap";
import useRedeem from "./util";

const RedeemPage = () => {
  const {
    formError,
    orderId,
    secretCode,
    serverName,
    onChangeOrderId,
    onChangeSecretCode,
    onChangeServerName,
    onSubmit,
  } = useRedeem();
  return (
    <div className="page-container">
      <h2 className="page-title">Redeem</h2>
      <div>
        <Row className="mt-4" xs="auto">
          <Col sm={12} md="auto" className="redeem-input-container py-2">
            <TextField
              className="secret-code-input"
              size="small"
              id="outlined-basic"
              label="Order Id"
              variant="outlined"
              value={orderId}
              onChange={onChangeOrderId}
            />
            {formError.orderId && (
              <div className="error-text mt-1">{formError.orderId}</div>
            )}
          </Col>
          <Col sm={12} md="auto" className="redeem-input-container py-2">
            <TextField
              className="secret-code-input"
              size="small"
              id="outlined-basic"
              label="Secret code"
              variant="outlined"
              value={secretCode}
              onChange={onChangeSecretCode}
            />
            {formError.secretCode && (
              <div className="error-text mt-1">{formError.secretCode}</div>
            )}
          </Col>
          <Col sm={12} md="auto" className="redeem-input-container py-2">
            <TextField
              className="secret-code-input"
              size="small"
              id="outlined-basic"
              label="Server name"
              variant="outlined"
              value={serverName}
              onChange={onChangeServerName}
            />
            {formError.serverName && (
              <div className="error-text mt-1">{formError.serverName}</div>
            )}
          </Col>
          <Col>
            <Button
              variant="contained"
              className="redeem-btn mt-2"
              onClick={onSubmit}
            >
              Redeem
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RedeemPage;
