import React from "react";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";

import useAccounts from "./util";
import AccountTable from "./accountTable";
import Pagination from "@mui/material/Pagination";
import Loader from "../../../components/loader";

const Accounts = () => {
  const history = useHistory();

  const {
    loading,
    accountData,
    csvData,
    csvHeaders,
    page,
    pageCount,
    handlePageChange,
  } = useAccounts();

  const goToDetails = (id) => {
    history.push({
      pathname: "orderdetails",
      state: { orderId: id },
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page-container">
      <div className="page-header-container">
        <h2 className="page-title">Accounts</h2>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={"account-report.csv"}
        >
          <button
            type="button"
            className="btn btn-blank download-btn f-semibold"
          >
            Download
          </button>
        </CSVLink>
      </div>
      <AccountTable data={accountData} goToDetails={goToDetails} />
      <Pagination
        count={pageCount}
        shape="rounded"
        page={page}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default Accounts;
