import moment from "moment";
import { useEffect, useState } from "react";
import { getRedeemedOrders } from "../../../api";

const useAccounts = () => {
  const [accountData, setAccountData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const pageSize = 25;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const csvHeaders = [
    { label: "Order Id", key: "orderId" },
    { label: "Ordered Date", key: "orderedDate" },
    { label: "Customer Name", key: "customerName" },
    { label: "Customer PhoneNumber", key: "customerPhoneNumber" },
    { label: "Customer Email", key: "customerEmail" },
    { label: "Total Price", key: "totalPrice" },
  ];

  const fetchOrderData = () => {
    setLoading(true);
    const body = {
      pageBy: {
        pageNo: page - 1,
        pageSize,
      },
    };
    getRedeemedOrders(body)
      .then((res) => {
        // console.log("Res", res);
        if (res.content) {
          setPageCount(Math.ceil(res.totalElements / pageSize));
          setAccountData(res.content);
          const csv = res.content.map((item) => {
            return {
              orderId: item.id,
              orderedDate: moment(item.createDate).format("DD-MM-YYYY"),
              customerName: item.hostName,
              customerPhoneNumber: item.hostPhoneNumber,
              customerEmail: item.hostEmail,
              totalPrice: item.totalPrice,
            };
          });
          setCsvData(csv);
          setLoading(false);
        }
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    fetchOrderData();
  }, [page]);

  return {
    loading,
    accountData,
    csvData,
    csvHeaders,
    page,
    pageCount,
    handlePageChange,
  };
};

export default useAccounts;
