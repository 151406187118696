import moment from "moment";
import React from "react";
import { Table } from "react-bootstrap";

const AccountTable = ({ data, goToDetails }) => {
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Order Id</th>
            <th>Date</th>
            <th>Customer Name</th>
            <th>Customer Phone Number</th>
            <th>Customer Email</th>
            <th>Amount $</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr className="table-row" key={item.id}>
              <td>{item.id}</td>
              <td>{moment(item.createdDate).format("DD-MM-YYYY")}</td>
              <td>{item.hostName}</td>
              <td>{item.hostPhoneNumber}</td>
              <td>{item.hostEmail}</td>
              <td>{Number(item.totalPrice).toFixed(2)}</td>
              <td>
                <button
                  className="btn-blank orderview-btn"
                  onClick={() => goToDetails(item.id)}
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AccountTable;
