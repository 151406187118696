import { useEffect, useState } from "react";
import { getMenus } from "../../../api";

const useShopMenu = () => {
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMenu = () => {
    setLoading(true);
    const body = {
      pageBy: {
        pageNo: 0,
        pageSize: 1000,
      },
    };
    getMenus(body)
      .then((res) => {
        setMenu(res);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    fetchMenu();
  }, []);

  return { menu, loading };
};

export default useShopMenu;
