import moment from "moment";

const numbers = /[0-9]/g;
const phoneno = /^\d{10}$/;

export const validateEmail = (value) => {
  let error = false;
  if (!value) {
    error = "Email required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
};

export const validatePassword = (value) => {
  let error = false;
  if (!value) {
    error = "Password Required";
  } else if (!value.match(numbers)) {
    error = "Password should contain atleast one number";
  } else if (value.length < 6) {
    error = "Password should be minimum 6 characters";
  }
  return error;
};

export const validateName = (value, text = "") => {
  let error = false;
  if (!value) {
    error = text ? `${text} required` : "Required";
  }
  return error;
};

export const validateNumber = (value, text = "") => {
  let error = false;
  if (!value) {
    error = text ? `${text} required` : "Required";
  } else if (isNaN(value)) {
    error = "Invalid Amount";
  }
  return error;
};

export const validateArray = (value, text = "") => {
  let error = false;
  if (value.length === 0) {
    error = text ? `${text} required` : "Required";
  }
  return error;
};

export const validateCardNumber = (value, text = "") => {
  let error = false;
  if (!value) {
    error = text ? `${text} required` : "Required";
  } else if (isNaN(value)) {
    error = "Invalid card number";
  }
  return error;
};

export const validateCardExpiryDate = (value) => {
  let error = false;
  let date = moment(value, "MM/YYYY", true);
  if (!value) {
    error = `Required`;
  } else if (!date.isValid()) {
    error = "Invalid date format";
  }
  return error;
};

export const validatePhone = (value) => {
  let error = false;
  if (!value) {
    error = "Phone number required";
  } else if (!value.match(phoneno)) {
    error = "Invalid phone number";
  }
  return error;
};

export const validateDate = (value) => {
  let error;
  if (!value || value === "") {
    error = "Please select a date";
  }
  return error;
};

export const validateTime = (value) => {
  let error;
  if (!value || value === "") {
    error = "Please select a time";
  }
  return error;
};

export const validateStartDate = (value) => {
  const today = moment().format("DD/MM/YYYY");
  const startDt = moment(value).format("DD/MM/YYYY");

  const err = moment(startDt, "DD/MM/YYYY").isBefore(
    moment(today, "DD/MM/YYYY")
  );

  let error;
  if (!value || value === "") {
    error = "Please select a date";
  } else if (err) {
    error = "Please select a valid start date";
  }
  return error;
};

export const validateDateComparison = (endDate, startDate) => {
  let error;
  if (!endDate || endDate === "") {
    error = "Please select a date";
  } else if (moment(endDate).isBefore(startDate)) {
    error = "Please select a valid end date";
  }
  return error;
};

export const validateEndTime = (startDate, endDate, startTime, endTime) => {
  let error;
  const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
  const endDateFormatted = moment(endDate).format("YYYY-MM-DD");
  const startDateTime = moment(
    `${startDateFormatted} ${startTime}`,
    "YYYY-MM-DD HH:mm"
  ).toISOString();

  const endDateTime = moment(
    `${endDateFormatted} ${endTime}`,
    "YYYY-MM-DD HH:mm"
  ).toISOString();

  if (!endDate || endTime === "") {
    error = "Please select a time";
  } else if (moment(endDateTime).isBefore(startDateTime)) {
    error = "Please select a valid end time";
  }
  return error;
};

export const validateExpirationDate = (startDate, expDate) => {
  let error;
  if (!expDate || expDate === "") {
    error = "Please select a date";
  } else if (
    moment(expDate).isAfter(startDate) ||
    moment(expDate).isBefore(moment().add(-1, "day"))
  ) {
    error = "Please select a valid expiration date";
  }
  return error;
};

export const validateExpirationTime = (
  startDate,
  expDate,
  startTime,
  expTime
) => {
  let error;
  const startDateFormatted = moment(startDate).format("YYYY-MM-DD");
  const expDateFormatted = moment(expDate).format("YYYY-MM-DD");

  const startDateTime = moment(
    `${startDateFormatted} ${startTime}`,
    "YYYY-MM-DD HH:mm"
  ).toISOString();

  const expirationDateTime = moment(
    `${expDateFormatted} ${expTime}`,
    "YYYY-MM-DD HH:mm"
  ).toISOString();

  if (!expDate || expTime === "") {
    error = "Please select a time";
  } else if (moment(expirationDateTime).isAfter(startDateTime)) {
    error = "Please select a valid expiration time";
  }
  return error;
};
