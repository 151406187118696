import { getCurrentUser } from "../helpers/Utils";
import urls from "./Urls";
import { getData, postData } from "./Utils";

const userLogin = async (body) => {
  const res = await postData(`${urls.loginUrl}`, body);
  return Promise.resolve(res);
};

const redeemItem = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.redeemItem}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const getRedeemedOrders = async (body) => {
  const user = getCurrentUser();
  const res = await postData(
    `${urls.getRedeemedOrders}/${user.secretKey}/${user.id}`,
    body
  );
  return Promise.resolve(res);
};

const getOrderDetailsById = async (id) => {
  const user = getCurrentUser();
  const res = await getData(
    `${urls.getOrderDetails}/${user.secretKey}/${user.id}/${id}`
  );
  return Promise.resolve(res);
};

const getMenus = async () => {
  const user = getCurrentUser();
  const res = await getData(`${urls.getMenus}/${user.secretKey}/${user.id}`);
  return Promise.resolve(res);
};

export {
  userLogin,
  redeemItem,
  getRedeemedOrders,
  getOrderDetailsById,
  getMenus,
};
