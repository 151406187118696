import { useCallback, useState } from "react";
import { redeemItem } from "../../../api";
import useAlert from "../../../hooks/alert-hook";
import { validateName } from "../../../validation/validator";

const useRedeem = () => {
  const [orderId, setOrderId] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [serverName, setServerName] = useState("");
  const [formError, setFormError] = useState(false);

  const { showAppAlert } = useAlert();

  const onChangeOrderId = useCallback((e) => {
    setOrderId(e.target.value);
  }, []);

  const onChangeSecretCode = useCallback((e) => {
    setSecretCode(e.target.value);
  }, []);

  const onChangeServerName = useCallback((e) => {
    setServerName(e.target.value);
  }, []);

  const resetForm = () => {
    setOrderId("");
    setSecretCode("");
    setServerName("");
  };

  const validator = {
    orderId: validateName(orderId),
    secretCode: validateName(secretCode),
    serverName: validateName(serverName),
  };

  const checkFormError = () => {
    setFormError(validator);
  };

  const onSubmit = () => {
    checkFormError();
    const invalidForm = Object.values(validator).some((x) => x !== false);
    const body = {
      orderId: orderId,
      secretCode: secretCode,
      serverName: serverName,
    };
    // console.log("Redeem Body", body);
    if (!invalidForm) {
      redeemItem(body).then((res) => {
        if (res.httpStatus === "OK") {
          showAppAlert("success", "Redeemed successfully");
          resetForm();
        }
      });
    }
  };

  return {
    formError,
    orderId,
    secretCode,
    serverName,
    onChangeOrderId,
    onChangeSecretCode,
    onChangeServerName,
    onSubmit,
  };
};

export default useRedeem;
