import React from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import AppLayout from "../../layout/AppLayout";
import AccountPage from "./accounts/index";
import ShopMenu from "./shopMenu/index";
import RedeemPage from "./redeemPage/index";
import OrderDetalis from "./accounts/orderDetails/index";
import ViewError from "../error";
import ViewUnauthorized from "../unauthorized";

// const AccountPage = React.lazy(() => import("./accounts/index"));
// const ShopMenu = React.lazy(() => import("./shopMenu/index"));
// const RedeemPage = React.lazy(() => import("./redeemPage/index"));

// const OrderDetalis = React.lazy(() => import("./accounts/orderDetails/index"));

// const ViewError = React.lazy(() => import("../error"));
// const ViewUnauthorized = React.lazy(() => import("../unauthorized"));

const App = () => {
  let match = useRouteMatch();

  return (
    <AppLayout>
      <>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/accounts`} />
          <Route
            path={`${match.url}/accounts`}
            render={(props) => <AccountPage {...props} />}
          />
          <Route
            path={`${match.url}/menu`}
            render={(props) => <ShopMenu {...props} />}
          />
          <Route
            path={`${match.url}/redeem`}
            render={(props) => <RedeemPage {...props} />}
          />
          <Route
            path={`${match.url}/orderdetails`}
            render={(props) => <OrderDetalis {...props} />}
          />
          <Route
            path="/error"
            exact
            render={(props) => <ViewError {...props} />}
          />
          <Route
            path="/unauthorized"
            exact
            render={(props) => <ViewUnauthorized {...props} />}
          />
          <Redirect to="/error" />
        </Switch>
      </>
    </AppLayout>
  );
};

export default withRouter(App);
