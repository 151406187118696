import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { userLogin } from "../../../api";
import { setCurrentUser } from "../../../helpers/Utils";
import { validateEmail, validatePassword } from "../../../validation/validator";

const useLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const onChangeEmail = useCallback((e) => {
    setError(false);
    setEmail(e.target.value);
  }, []);

  const onChangePassword = useCallback((e) => {
    setError(false);
    setPassword(e.target.value);
  }, []);

  const validator = {
    email: validateEmail(email),
    password: validatePassword(password),
  };

  const checkFormError = () => {
    setFormError(validator);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    checkFormError();
    const invalidForm = Object.values(validator).some((x) => x !== false);

    const body = {
      email,
      password,
    };

    if (!invalidForm) {
      setLoading(true);
      userLogin(body)
        .then((res) => {
          if (res.id) {
            setCurrentUser(res);
            setLoading(false);
            history.push("/app");
          }
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    }
  };

  return {
    loading,
    email,
    password,
    error,
    formError,
    onChangeEmail,
    onChangePassword,
    onSubmit,
  };
};

export default useLogin;
