import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdRestaurantMenu, MdRedeem } from "react-icons/md";

const sidemenuData = [
  {
    id: "accounts",
    label: "Accounts",
    icon: <FaMoneyCheckAlt size={20} />,
    to: `accounts`,
  },
  {
    id: "menu",
    label: "Menu",
    icon: <MdRestaurantMenu size={23} />,
    to: `menu`,
  },
  {
    id: "redeem",
    label: "Redeem",
    icon: <MdRedeem size={20} />,
    to: `redeem`,
  },
];

export default sidemenuData;
