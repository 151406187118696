import React, { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem, SidebarHeader } from "react-pro-sidebar";
import { useHistory } from "react-router";
import { adminRoot, sidemenuData } from "../../constants";
import sidebarImage from "../../assets/images/sidemenu-background.jpg";
import { AiOutlineMenuFold } from "react-icons/ai";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";

const SideBar = ({ toggleSideBar, onToggleSideBar }) => {
  const history = useHistory();
  const [collapseState, setCollapseState] = useState(false);

  const onToggleCollapse = () => {
    if (toggleSideBar) {
      onToggleSideBar(false);
    } else {
      setCollapseState((prev) => !prev);
    }
  };

  useEffect(() => {
    if (toggleSideBar) {
      setCollapseState(false);
    }
  }, [toggleSideBar]);

  const onClickMenu = (item) => {
    if (toggleSideBar) {
      onToggleSideBar(false);
    }
    history.push(`${adminRoot}/${item.to}`);
  };

  return (
    <ProSidebar
      className="sidebar-container"
      collapsed={collapseState}
      collapsedWidth={75}
      image={sidebarImage}
      width={200}
      breakPoint="sm"
      toggled={toggleSideBar}
    >
      <SidebarHeader className="px-3 py-2">
        <Row>
          {!collapseState && (
            <Col xs={8}>
              <h5 className="f-semibold">Shop Owner</h5>
            </Col>
          )}
          <Col
            xs={collapseState ? 12 : 4}
            className={collapseState ? "text-center" : "text-end"}
          >
            <button className="btn-blank" onClick={onToggleCollapse}>
              {collapseState ? (
                <AiOutlineMenuUnfold size={25} />
              ) : (
                <AiOutlineMenuFold size={25} />
              )}
            </button>
          </Col>
        </Row>
      </SidebarHeader>
      <Menu>
        {sidemenuData.map((item) => (
          <MenuItem
            icon={item.icon}
            key={item.id}
            onClick={() => onClickMenu(item)}
          >
            <h6 className="f-medium pt-2">{item.label}</h6>
          </MenuItem>
        ))}
      </Menu>
    </ProSidebar>
  );
};

export default SideBar;
