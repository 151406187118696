import React from "react";
import OrderedItemRow from "./itemRow";

const OrderedItems = ({ data }) => {
  return (
    <div className="white-card px-3 py-3">
      <h5 className="card-title">Ordered Items</h5>
      <div className="horizontal-line" />
      <div className="ordered-item-content-container">
        {data.map((item) => (
          <OrderedItemRow item={item} />
        ))}
      </div>
    </div>
  );
};

export default OrderedItems;
